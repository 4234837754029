:root {
	--bs-link-hover-color: inherit;
	--bs-body-bg: #ffebf2;
	--phoenix-purple: #5a0526;
	--phoenix-light-purple: #ffebf2;
	--bs-emphasis-color: var(--phoenix-purple);
	--bs-border-color: var(--phoenix-purple);
	--bs-secondary-bg: var(--phoenix-light-purple);
	--bs-tertiary-bg: var(--phoenix-light-purple);
	--bs-btn-disabled-border-color: var(--phoenix-light-purple);
	--bs-list-group-action-hover-bg: var(--phoenix-purple);
}

body {
	font-family: Montserrat;
	overflow: auto !important;
	padding-right: 0 !important;
}

.App {
	text-align: center;
	color: var(--phoenix-purple);
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

html {
	color: var(--phoenix-purple);
}

a:hover a:focus {
	color: #000 !important;
}

.login-color {
	background-color: var(--phoenix-purple);
	color: #fff;
}

.login-text-override {
	color: rgb(83, 83, 83);
}

.bg-purple {
	background-color: var(--phoenix-purple);
	color: var(--phoenix-light-purple);
}

.bg-light-purple {
	background-color: var(--phoenix-light-purple);
	color: var(--phoenix-purple);
}

.bg-white {
	background-color: #ffffff;
	color: #000;
}

.rounded {
	border-radius: 25px;
}

.rounded-bottom {
	border-radius: 0px 0px 25px 25px;
}

.rounded-right {
	border-radius: 0px 25px 25px 0px;
}

.rounded-left {
	border-radius: 25px 0px 0px 25px;
}

.padding {
	padding: 15px;
}

.padding-sm {
	padding: 2px;
}

.padding-md {
	padding: 8px;
}

.padding-right-0 {
	padding-right: 0px;
}

.padding-left-0 {
	padding-left: 0px;
}

.padding-0 {
	padding: 0px;
}

.padding-right-2 {
	padding-right: 2px;
}

.padding-left-2 {
	padding-left: 2px;
}

.margin {
	margin: 15px;
}

.margin-sm {
	margin: 2px;
}

.margin-bottom {
	margin-bottom: 15px;
}

.margin-left {
	margin-left: 15px;
}

.margin-right {
	margin-right: 15px;
}

.margin-left-right {
	margin-left: 15px;
	margin-right: 15px;
}

.margin-left-sm {
	margin-left: 2px;
}

.margin-right-sm {
	margin-right: 2px;
}

.margin-bottom-sm {
	margin-bottom: 5px;
}

.margin-top {
	margin-top: 15px;
}

.margin-top-bottom {
	margin-top: 5px;
	margin-bottom: 5px;
}

.margin-top-bottom-lg {
	margin-top: 15px;
	margin-bottom: 15px;
}

.text-right {
	text-align: right;
}

.height-200 {
	height: 200px;
	width: auto;
}

.max-height-350 {
	max-height: 350px;
	width: auto;
	display: inline-block;
}

.height-700 {
	height: 700px;
	width: auto;
}

.width-50 {
	width: 50%;
}

.width-75 {
	width: 75%;
}

.full-height {
	height: 100%;
}

.complete-row {
	background-color: #6ddf56;
	color: #000;
}

.text-purple {
	color: var(--phoenix-purple);
}

.text-light-purple {
	color: var(--phoenix-light-purple);
}

.form-control:focus {
	border-color: var(--phoenix-purple);
	box-shadow: 0 0 0 0.25rem rgba(90, 5, 38, 0.25);
}

.text-sm {
	font-size: small;
}

.bg-red {
	color: #ad0000;
}

.display-inline {
	display: inline;
}
